/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Normalmente no suelo poner este tipo de cosas pero me hizo gracia ", React.createElement(_components.a, {
    href: "http://www.geekets.com/2008/02/07/10-razones-por-las-que-alejar-a-tu-novia-de-un-geek/"
  }, "este"), " post que me han confirmado que es totalmente verdad, así que geeks del mundo ya sabéis, lo tenemos bien facil."), "\n", React.createElement(_components.p, null, "Saludos"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
